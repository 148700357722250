import usePasskey from '@/hooks/passkey/usePasskey';
import { TurnkeyUserLoginQuery } from '@cyber/service/pwa';
import { LoadingButton } from '@cyberlab/uikit';

import { useAuth } from '@/hooks';
import { Icon } from '@link3-pwa/icons';
import { useRouter } from 'next/router';

function LoginButton() {
  const { handleLogin: turnkeyLogin, isLogining } = usePasskey();
  const { changeAccessToken } = useAuth();
  const { replace } = useRouter();

  const handleLogin = () => {
    turnkeyLogin({
      onSuccess(data: TurnkeyUserLoginQuery['turnkeyUserLogin']['user']) {
        if (data?.privateInfo?.accessToken) {
          changeAccessToken(data.privateInfo.accessToken);
          replace('/home');
        }
      },
    });
  };

  return (
    <LoadingButton
      loading={isLogining}
      variant='outlined'
      onClick={handleLogin}
      fullWidth
      startIcon={<Icon name='icon-key-line' color='#ffffff' />}
      sx={{
        '& .MuiButton-startIcon': {
          marginRight: '10px',
        },
      }}
    >
      Sign in with your passkey
    </LoadingButton>
  );
}

export default LoginButton;
