import { Box, CircularProgress, Stack } from '@cyberlab/uikit';
import { memo } from 'react';

const AnimatedBorder = ({ delay = 0 }: { delay?: number }) => (
  <Box
    width='100%'
    height='100%'
    position='absolute'
    top={0}
    left={0}
    borderRadius={100}
    sx={({ palette }) => ({
      '@keyframes wave': {
        '0%': {
          transform: 'scale(1)',
          opacity: 1,
        },
        '100%': {
          transform: 'scale(1.75)',
          opacity: 0,
        },
      },
      outline: '1px solid',
      outlineColor: palette.mode === 'dark' ? '#434343' : palette.bg?.[100],
      animation: 'wave 3s ease infinite',
      animationDelay: `${delay}s`,
    })}
  ></Box>
);

const Loading = () => {
  return (
    <Stack
      position='relative'
      alignItems='center'
      justifyContent='center'
      width={88}
      height={88}
      borderRadius={100}
      bgcolor='brand.main'
      sx={{ filter: 'drop-shadow(0px 0px 20px rgba(13, 180, 140, 0.33))' }}
    >
      <CircularProgress size={54} sx={{ color: '#f5f5f5' }} />

      <AnimatedBorder />
      <AnimatedBorder delay={1} />
      <AnimatedBorder delay={2} />
    </Stack>
  );
};

export default memo(Loading);
