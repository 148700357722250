import { LoadingButton, Stack } from '@cyberlab/uikit';

import useModalState from '@/hooks/useModalState';
import { Icon } from '@link3-pwa/icons';
import CreateAccountModal from './CreateAccountModal';

function CreateButton() {
  const {
    isOpen: isCreateAccountModalOpen,
    openModal: openCreateModal,
    closeModal: closeCreateModal,
  } = useModalState();

  return (
    <Stack gap={2}>
      <LoadingButton
        variant='contained'
        onClick={openCreateModal}
        fullWidth
        startIcon={<Icon name='icon-add-line' color='text.1000' />}
        sx={{
          '& .MuiButton-startIcon': {
            marginRight: '10px',
          },
        }}
      >
        Sign up with a passkey
      </LoadingButton>

      {/* TODO: recovery will be availible in next sprint */}
      {/* <Text
        variant='body2'
        color='text.link'
        textAlign='right'
        sx={{ textDecoration: 'underline', opacity: 0, mt: -1 }}
      >
        Lose Passkey
      </Text> */}

      <CreateAccountModal onClose={closeCreateModal} open={isCreateAccountModalOpen} />
    </Stack>
  );
}

export default CreateButton;
