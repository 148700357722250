import { useState } from 'react';

function useModalState() {
  const [isOpen, toggleIsOpen] = useState(false);

  const closeModal = () => {
    toggleIsOpen(false);
  };

  const openModal = () => {
    toggleIsOpen(true);
  };

  return {
    isOpen,
    openModal,
    closeModal,
  };
}

export default useModalState;
