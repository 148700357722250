import { useAuth } from '@/hooks';
import { useSignModal } from '@/views/Modals/SignModal';
import CreateButton from '@/views/Passkey/CreateButton';
import LoginButton from '@/views/Passkey/LoginButton';

import { useAccount } from '@cyber-co/sdk/wagmi';
import { useIsomorphicLayoutEffect } from '@cyber-co/utils';
import { Box, LoadingButton, Stack, Text, darkTheme } from '@cyberlab/uikit';
import Icon from '@link3-pwa/icons';
import { ThemeProvider } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

function Landing(props: any) {
  const { isLoggedIn, login, signAndVerify } = useAuth();
  const { isConnected } = useAccount();

  const { openModal: openSignModal, closeModal: closeSignModal } = useSignModal();

  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (isLoggedIn) {
      router.replace('/home');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isConnected && !isLoggedIn) {
      setTimeout(() => {
        openSignModal();
      }, 250);
    } else if (isLoggedIn) {
      closeSignModal();
    }
  }, [isConnected, isLoggedIn]);

  return (
    <ThemeProvider theme={darkTheme}>
      <Stack
        height={'calc(100vh - 56px)'}
        position='relative'
        sx={{
          backgroundImage: 'url(/assets/bg/login-bg-new.png)',
          backgroundSize: '100%',
          justifyContent: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {!isLoggedIn ? (
          <>
            <Stack flex='auto' pt={4} pl={3} justifyContent='space-between'>
              <img src='/assets/logos/link3-logo-full-white.svg' width={112} height={25} loading='eager' />
              <Stack justifyContent='space-between' gap={3}>
                <Text variant='h5' bold fontStyle='italic'>
                  Professional networking reimagined!
                  {/* Web3 professional network where verified identities meet value-based communication. */}
                </Text>
                <Box
                  height='1px'
                  width='100%'
                  sx={{
                    background:
                      'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 21.68%, #FFF 63.58%, rgba(255, 255, 255, 0.00) 100%)',
                  }}
                ></Box>
              </Stack>
            </Stack>
            <Stack
              gap={3}
              height={422}
              flex='none'
              bgcolor='rgba(20,20,20,0.2)'
              pt={4}
              px={2}
              sx={{
                backdropFilter: 'blur(50px)',
              }}
            >
              <Stack gap={3}>
                <Text color='text.100' bold>
                  New to Link3?
                </Text>
                <CreateButton />
              </Stack>
              <Stack gap={3}>
                <Text color='text.100' bold>
                  Existing user?
                </Text>
                <Stack alignItems='center' gap={2}>
                  <LoginButton />
                  <LoadingButton
                    variant={isConnected ? 'contained' : 'outlined'}
                    onClick={isConnected ? () => openSignModal() : login.login}
                    fullWidth
                    startIcon={<Icon name='icon-wallet-outlined' color={isConnected ? '#000' : 'text.primary'} />}
                    sx={{
                      '& .MuiButton-startIcon': {
                        marginRight: '10px',
                      },
                    }}
                    loading={login.isLoading || signAndVerify.isLoading}
                  >
                    {isConnected ? 'Verify your address' : 'Connect Wallet'}
                  </LoadingButton>
                  <Text mt={-1} variant='body2' color='text.250'>
                    *Recommended for existing users
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </>
        ) : null}
      </Stack>
    </ThemeProvider>
  );
}

export default Landing;
