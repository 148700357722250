import Loading from '@/component/Loading';
import { useAuth } from '@/hooks';
import { actions } from '@/store';
import { TRootState } from '@/store/store';
import { Button, Drawer, Flex, LoadingButton, Stack, Text } from '@cyberlab/uikit';
import { Icon } from '@link3-pwa/icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useDisconnect } from 'wagmi';

function SignModal() {
  const [isSigning, toggleIsSigning] = useState(false);

  const { isOpen, sign, closeModal, openModal } = useSignModal();
  const { signAndVerify } = useAuth();
  const { isConnecting, isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const isLoading = isConnecting || isSigning;

  const handleSign = async () => {
    try {
      toggleIsSigning(true);
      await (sign ? sign() : signAndVerify.signAndVerify());
      closeModal();
    } catch (error) {
      toggleIsSigning(false);
    }
  };

  const handleCancel = async () => {
    if (isConnected) {
      await disconnectAsync();
    }
    closeModal();
  };

  useEffect(() => {
    if (!isOpen) {
      return () => {
        toggleIsSigning(false);
      };
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      needCloseIcon={!isLoading}
      contentSx={{ height: 342 }}
      title={''}
      onClose={isLoading ? undefined : handleCancel}
    >
      {isLoading || isConnecting ? (
        <Stack height='100%' justifyContent='center' alignItems='center'>
          <Loading />

          <Text color='text.250' variant='body2' textAlign='center' mt={8} sx={{ transition: 'none' }}>
            If it remains in loading for a long time, please check your network status or confirm again within the
            wallet app.
          </Text>
        </Stack>
      ) : (
        <>
          <Stack gap={1.5} alignItems='center' mt={20 / 8}>
            <Icon name='icon-body-sign-line' size={50} />

            <Text bold>Verify your account</Text>

            <Text variant='body2' textAlign='center'>
              To finish connecting, you must sign a message in your wallet to verify that you are the owner of this
              account.
            </Text>
          </Stack>
          <Flex gap={2} mt={5}>
            <Button onClick={handleCancel} disabled={isLoading} variant='outlined' fullWidth>
              Cancel
            </Button>
            <LoadingButton loading={isLoading} onClick={handleSign} variant='contained' fullWidth>
              Send Message
            </LoadingButton>
          </Flex>
        </>
      )}
    </Drawer>
  );
}

export const useSignModal = () => {
  const { isOpen, sign } = useSelector((state: TRootState) => state.global.signModalStatus);
  const dispatch = useDispatch();

  const closeModal = useCallback(async () => {
    dispatch(
      actions.global.setSignModalStatus({
        isOpen: false,
      }),
    );
  }, []);

  const openModal = useCallback((sign?: () => Promise<void>) => {
    dispatch(actions.global.setSignModalStatus({ isOpen: true, sign }));
  }, []);

  return {
    isOpen,
    sign,
    closeModal,
    openModal,
  };
};

export default SignModal;
