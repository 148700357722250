import Loading from '@/component/Loading';
import { useAuth } from '@/hooks';
import usePasskey from '@/hooks/passkey/usePasskey';
import {
  RegisterTurnkeyUserRequest,
  RegisterTurnkeyUserResponse_Status,
  TurnkeyUserLoginQuery,
  useTurnkeyUserRegisterMutation,
} from '@cyber/service/pwa';
import { Button, Drawer, Flex, Stack, Text } from '@cyberlab/uikit';
import { ModalProps } from '@cyberlab/uikit/src/Modal';
import { Icon } from '@link3-pwa/icons';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

function CreateAccountModal(props: Pick<ModalProps, 'open' | 'onClose'>) {
  const { handleCreate, isCreating, handleLogin, isLogining } = usePasskey();
  const { changeAccessToken } = useAuth();
  const { replace } = useRouter();

  const [registerTurnkey, { isRegistered }] = useTurnkeyUserRegisterMutation({
    selectFromResult({ data }) {
      return {
        isRegistered: data?.turnkeyUserRegister.status === RegisterTurnkeyUserResponse_Status.Success,
      };
    },
  });

  const handleRegister = async () => {
    const username = `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')} CyberAccount`;

    const register = async (request: RegisterTurnkeyUserRequest) =>
      registerTurnkey({ request }).then((res) => {
        return {
          isSuccess:
            'data' in res && res.data.turnkeyUserRegister.status === RegisterTurnkeyUserResponse_Status.Success,
        };
      });

    const res = await handleCreate({ username, register });

    if (!res.isSuccess) {
      props.onClose?.();
    }
  };

  const handleConfirm = async () => {
    const handleSuccess = (user: TurnkeyUserLoginQuery['turnkeyUserLogin']['user']) => {
      if (user?.privateInfo?.accessToken) {
        changeAccessToken(user.privateInfo.accessToken);
        replace('/home');
      }
    };
    await handleLogin({ onSuccess: handleSuccess });
  };

  useEffect(() => {
    if (props.open && !isCreating) {
      handleRegister();
    }
  }, [props.open]);

  const renderContent = () => {
    if (isCreating || isLogining) {
      return (
        <Flex height='80%' width='100%' alignItems='center' justifyContent='center'>
          <Loading />
        </Flex>
      );
    }

    if (!isCreating && isRegistered) {
      return (
        <>
          <Stack gap={18 / 8} alignItems='center' mt={2}>
            <Icon name='icon-passkey-line' size={50} />

            <Text bold variant='subtitle1' textAlign='center'>
              One Last Step!
            </Text>
          </Stack>

          <Button onClick={handleConfirm} sx={{ mt: 7 }} variant='contained' color='primary'>
            Confirm Authentication
          </Button>
        </>
      );
    }
  };

  return (
    <Drawer
      {...props}
      contentSx={{ height: 265, mt: '-1px' }}
      title={isCreating || isRegistered ? 'Login with Passkey' : 'Create Account'}
    >
      {renderContent()}
    </Drawer>
  );
}

export default CreateAccountModal;
